/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import withApollo from 'next-with-apollo'
import { ApolloClient, ApolloProvider } from '@apollo/client'
// import { persistCache } from 'apollo3-cache-persist'

import { createCache } from './cache'
import typeDefs from './typeDefs/local'

let globalApolloClient = null

// Export a HOC from next-with-apollo
// Docs: https://www.npmjs.com/package/next-with-apollo
export default withApollo(
  ({ ctx, initialState }) => initializeApollo(ctx, initialState),
  {
    render: ({ Page, props }) => {
      const client = props.apollo
      if (props?.pageProps?.apolloStaticCache)
        client.cache.restore({
          ...client.cache.extract(),
          ...props?.pageProps?.apolloStaticCache,
        })
      return (
        <ApolloProvider client={client}>
          <Page {...props} />
        </ApolloProvider>
      )
    },
  }
)

export const initializeApollo = (ctx = {}, initialState) => {
  if (typeof window === 'undefined') {
    return createApolloClient(ctx, initialState)
  }

  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(ctx, initialState)
  }

  return globalApolloClient
}

const createApolloClient = (ctx = {}, initialState) => {
  const ssrMode = typeof window === 'undefined'
  const cache = createCache(initialState)

  // if (!ssrMode)
  //   persistCache({
  //     cache,
  //     storage: window?.localStorage,
  //   })

  return new ApolloClient({
    link: createIsomorphicLink(ctx),
    ssrMode,
    cache,
    typeDefs,
  })
}

const createIsomorphicLink = (ctx) => {
  if (typeof window === 'undefined') {
    const { SchemaLink } = require('apollo-link-schema')
    const { schema } = require('./schema')
    return new SchemaLink({ schema, context: ctx })
  } else {
    const { HttpLink } = require('@apollo/client')
    return new HttpLink({
      uri: '/api/graphql',
      credentials: 'same-origin',
    })
  }
}
