import { InMemoryCache } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'

import { userVar } from './localStorage'

export const createCache = (initialState) => {
  //  deepcode ignore PureFunctionReturnValueIgnored: this is fine

  return new InMemoryCache({
    possibleTypes: {
      TeamMemberInterface: ['User', 'Contact'],
      File: ['Photo', 'Document'],
    },
    typePolicies: {
      Region: {
        keyFields: () => uuidv4(),
      },
      Program: {
        keyFields: () => uuidv4(),
      },
      Query: {
        fields: {
          loggedInUser: {
            read() {
              return userVar()
            },
          },
        },
      },
    },
  }).restore(initialState || {})
}
